<!--  -->
<template>
  <div class=''>
    <PageNAV v-show="ISshow"></PageNAV>
    <router-view v-wechat-title='$route.meta.title'></router-view>
    <pagebottom></pagebottom>
    <contact></contact>
  </div>
</template>

<script>
import pagebottom from './components/pagebottom.vue'
import PageNAV from './components/pageNAV.vue'
import contact from './components/contact.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    pagebottom,
    PageNAV,
    contact
  },
  data() {
    //这里存放数据
    return {
      scrollNum: 0,
      ISshow: true
    };
  },
  methods: {
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // console.log("滚动", scrollTop);
      if (scrollTop >= 100) {
        this.ISshow = false;
      } else {
        this.ISshow = true;
      }
      if (this.scrollNum - scrollTop >= 100) {
        console.log("返回顶部大于100");
        this.ISshow = true;
        // this.timer = setTimeout(() => { 
        //   this.ISshow = false;
        // }, 2000)
      }
      this.scrollNum = scrollTop;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {

  },
}
</script>
<style > 
</style>