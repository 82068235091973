<template>
    <div class='PageNAV'>
        <div class="logo">
            <img src="../assets/navLogo1.png" alt="">
        </div>
        <div class="head">
            <div class="navItem" :class="{ 'active': item.url == active }" @click="NavChange(item)"
                v-for="(item, index) in headerData" :key="index">{{ item.name }} </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        //这里存放数据
        return {
            headerData: [{
                name: '首页',
                url: '/home',
            },
            {
                name: '公司介绍',
                url: '/introduce',
            },
            {
                name: '投资管理',
                url: '/manage',
            },
            {
                name: '企业布局',
                url: '/layout',
            },
            {
                name: '资讯中心',
                url: '/information/0',
            },
            {
                name: '联系我们',
                url: '/contactMe',
            },
            ],
            active: '',
        };
    },
    watch: {
        "$route": {
            imediate: true,
            handler(newVal) {
                // console.log( "路由" , newVal );
                this.active = newVal.path;
                localStorage.setItem('active', this.active);
            }
        }
    },
    //方法集合
    methods: {
        NavChange(item) {
            this.active = item.url;
            this.$router.push(item.url);
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.active = localStorage.getItem('active');
    },
    mounted() {

    },
}
</script>
<style scoped>
.PageNAV {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5625rem;
}

.logo {
    width: 18.75rem;
    padding-top: 1.875rem;
}
.logo img{
    width: 18rem; 
}

.head {
    display: flex;
    background-color: rgba(20, 50, 70, 0.1);
    height: 5.125rem;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0px .1875rem .375rem rgba(20, 50, 70, 0.1);
    backdrop-filter: blur(.25rem);
}

.head .navItem {
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: center;
    width: 7.8125rem;
    height: 5.125rem;
    line-height: 6.25rem;
    font-size: .875rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
}

.active { 
    background: rgba(191, 42, 59, 1);
}

@media only screen and (max-width: 540px) {
    
    .PageNAV {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        display: block;
        height: 41px;
    }

    .logo {
        display: block;
        width: 100%;
        padding-top: 0px; 
    }
    .logo img{
        width: 200px;
        display: block;
        margin: auto;
    }

    .head {
        display: flex;
        flex-wrap: wrap;
        background-color: rgba(20, 50, 70, 0.1);
        height: 41px;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0px 3px 6px rgba(20, 50, 70, 0.1);
        backdrop-filter: blur(4px);
    }

    .head .navItem {
        display: flex;
        justify-content: center;
        width: 125px;
        height: 41px;
        line-height: 41px;
        font-size: 12px;
        font-weight: 700; 
    }
}
</style>