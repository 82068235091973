import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
// 引入element-ui
import ElementUI from 'element-ui';                      // 引入element-ui
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 修改浏览器标签
import VueWechatTitle from 'vue-wechat-title'//动态修改title
Vue.use(VueWechatTitle)

import './assets/css/globle.css' 

// 复制插件
import VueClipBoard from 'vue-clipboard2';
Vue.use(VueClipBoard);

// 导入axios
import axios from 'axios'
Vue.prototype.$http = axios;
 

Vue.config.productionTip = false 
Vue.filter('DateFormat', function (originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDay() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

})

new Vue({
  render: h => h(App),
  router, 
}).$mount('#app')
