<template>
    <div class='contact' @click.capture="active = null">
        <div class="contactItem ssss" @mouseleave="pageWidth > compareNum ? leaveCode() : null">
            <img src="../assets/icon/weixin.png" class="ssss" alt=""
                @click="pageWidth <= compareNum ? active = active == 0 ? null : 0 : null"
                @mouseenter="pageWidth > compareNum ? enterCode(0) : null">
            <div class="ssss" :class="{ 'code': true, 'active': active == 0 }"
                @click.capture="copyText('13253436666', 0)" v-show="active == 0">132-5343-6666</div>
        </div>
        <div class="contactItem ssss" @mouseleave="pageWidth > compareNum ? leaveCode() : null">
            <img src="../assets/icon/phone.png" class="ssss" alt=""
                @click="pageWidth <= compareNum ? active = active == 1 ? null : 1 : null"
                @mouseenter="pageWidth >= compareNum ? enterCode(1) : null">
            <div class="ssss" :class="{ 'code': true, 'active': active == 1 }"
                @click.capture="copyText('13253436666', 1)" v-show="active == 1"> 132-5343-6666</div>
        </div>
        <div class="contactItem ssss">
            <img class="ssss" src="../assets/icon/goTop.png" alt="" @click="goTop">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        //这里存放数据
        return {
            active: null,
            scrollTop: 0,
            pageWidth: 1920,
            compareNum: 750,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
        this.pageWidth = window.innerWidth;
        window.addEventListener('resize', this.handleResize);
        // window.addEventListener('click', this.handleClick);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('resize', this.handleResize);
        // window.removeEventListener('click', this.handleClick);
    },
    methods: {
        leaveCode() {
            this.active = null
            console.log("离开")
        },
        enterCode(e) {
            this.active = e
            console.log("进入")
        },
        handleClick(event) {
            console.log('全局点击事件被触发', event.target.className);
            if (!event.target.className.includes('ssss')) {
                this.active = null
            }
        },
        copyText(text, type) {
            if (this.active) {
                this.active = type
                return
            }
            this.$copyText(text).then(() => {
                alert('复制成功');
            }, () => {
                alert('复制失败，请重试');
            });
        },
        handleResize() {
            this.pageWidth = window.innerWidth;
        },
        handleScroll() {
            this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        },
        goTop() {
            const duration = 500 // 动画持续时间
            const startPos = this.scrollTop // 当前滚动位置
            const startTime = performance.now() // 开始时间

            const animateScroll = (timestamp) => {
                const elapsed = timestamp - startTime // 已经过去的时间
                const progress = Math.min(elapsed / duration, 1) // 完成百分比
                const easeProgress = 0.5 - 0.5 * Math.cos(progress * Math.PI) // 缓动效果的百分比

                window.scrollTo(0, startPos * (1 - easeProgress)) // 滚动到顶部

                if (progress < 1) {
                    requestAnimationFrame(animateScroll)
                }
            }
            requestAnimationFrame(animateScroll)
        },
    }
}
</script>
<style lang="scss" scoped>
.contact {
    position: fixed;
    position: -webkit-fixed;
    position: -moz-fixed;
    position: -ms-fixed;
    top: 50vh;
    right: 0;
    z-index: 9999;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: end;

    .contactItem {
        cursor: pointer;
        height: 6rem;
        width: max-content;
        margin-bottom: 1.25rem;
        border-radius: 1rem 0px 0px 1rem;
        background-color: #FFFFFF;
        padding: 1rem 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 3.9881rem;
            height: 3.9888rem;
        }

        .code {
            margin-left: 10px;
            font-size: .875rem;
            font-weight: 700;
            color: rgba(20, 50, 70, 1);
        }
    }
} 

@media only screen and (max-width: 540px) {
    .contact {
        /* 
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        */

        .contactItem {
            height: 56px;
            margin-bottom: 16px;
            border-radius: 12px 0px 0px 12px;
            padding: 12px 10px;

            img {
                width: 40px;
                height: 40px;
            }

            .code {
                margin-left: 10px;
                font-size: 12px;
            }
        }
    }
}
</style>
