import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)

const routes = [
    {
        path: '/', 
        redirect: '/home'
    },
    {
        path: '/home',// 首页
        name: 'home',
        meta: {
            title: '首页'
        },
        component: () => import('../pages/homePage.vue')
    },
    {
        path: '/introduce',// 介绍
        name: 'introduce',
        meta: {
            title: '公司介绍'
        },
        component: () => import('../pages/introduce.vue')
    },
    {
        path: '/manage',// 管理
        name: 'manage',
        meta: {
            title: '投资管理'
        },
        component: () => import('../pages/manage.vue')
    },
    {
        path: '/layout',//布局
        name: 'layout',
        meta: {
            title: '企业布局'
        },
        component: () => import('../pages/layout.vue')
    },
    {
        path: '/information/:id', // 资讯
        name: 'information',
        meta: {
            title: '资讯中心'
        },
        component: () => import('../pages/information.vue')
    },
    {
        path: '/contactMe', // 联系我
        name: 'contactMe',
        meta: {
            title: '联系我们'
        },
        component: () => import('../pages/contactMe.vue')
    },
]

const router = new VueRouter({
    routes
})
// router.beforeEach((to, from, next) => {
//     if (to.path === '/login') return next();
//     const tokenStr = window.sessionStorage.getItem('token');
//     if (!tokenStr) return next('./login')
//     next();
// })

export default router