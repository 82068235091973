<template>
    <div class='pagebottom'>
        <div class="content">
            <img src="../assets/bottomlogo.png">
            <div class="footer_table">
                <div class="footer_table_left">
                    <div class="list" v-for="(item, index) in tableList" :key="index">
                        <div class="list_title">{{ item.title }}</div>
                        <div class="list_item" v-for="(ele, ind) in item.list" :key="ind">{{ ele }}</div>
                    </div>
                </div>
                <div class="line"> </div>
                <div class="footer_table_right">
                    <div>公司地址： 海南省海口市江东新区兴洋大道118号创客服务中心SZ88</div>
                    <div>公司电话： 15617721548</div>
                </div>
            </div>
            <div class="copyright">版权所有© 海南力冠投资有限责任公司 豫ICP备2023007874号-1</div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        //这里存放数据
        return {
            tableList: [
                {
                    title: '公司介绍',
                    list: ['公司简介', '组织架构', '企业理念']
                },
                {
                    title: '投资管理',
                    list: ['投资原则', '投资决策', '投资组合']
                },
                {
                    title: '企业布局',
                    list: []
                },
                {
                    title: '资讯中心',
                    list: []
                },
                {
                    title: '联系我们',
                    list: []
                },
            ]
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //方法集合
    methods: {

    },
}
</script>
<style lang='scss' scoped>
.pagebottom {
    background: rgba(191, 42, 59, 1);
    padding: 1rem 0 2rem;
    color: #fff;

    .content {
        width: 75rem;
        margin: 0 auto;
    }

    img {
        width: 11.85rem;
        min-width: 18.75rem;
    }

    .footer_table {
        display: flex;

        .footer_table_left {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .list_title {
                font-size: .875rem;
                font-weight: 700; 
                margin: .9375rem 0;
            }

            .list_item {
                font-size: .875rem;
                font-weight: 500; 
                margin: .5rem 0;
            }
        }

        .line {
            width: .0625rem;
            height: 5rem;
            margin: 1.25rem 3.75rem;
            background-color: #fff;
        }

        .footer_table_right {
            flex: 1;
            font-size: .875rem;
            font-weight: 500;

            div {
                margin: .9375rem 0 .3125rem;
            }
        }

    }

    .copyright {
        font-size: .75rem;
        font-weight: 500;
        line-height: 1.5rem;
        color: rgba(255, 255, 255, 1);
        text-align: center;
    }
}
 
@media only screen and (max-width: 540px) {

    .pagebottom {
        background: rgba(191, 42, 59, 1);
        padding: 16px 24px;
        color: #fff;

        .content {
            width: 100%;
            margin: 0 auto;
        }

        img {
            width: 155px;
            height: 26px;
        }

        .footer_table {
            display: block;

            .footer_table_left {
                flex: 1;
                display: flex;
                justify-content: space-between;

                .list_title {
                    font-size: 12px;
                    margin: 8px 0;
                    line-height: 16px;
                }

                .list_item {
                    font-size: 12px;
                    margin: 4px 0;
                    line-height: 16px;
                }
            }

            .line {
                width: 90%;
                height: 1px;
                margin: 12px auto;
            }

            .footer_table_right {
                font-size: 12px;

                div {
                    margin: 15px 0;
                }
            }
        }

        .copyright {
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
        }
    }
}
</style>